import { create } from 'zustand';

type Props = {
  isOpen: boolean;
  setOpen: (isOpen: boolean) => void;
  setOpenStore: () => void;
};

const useFeaturebaseStore = create<Props>((set) => ({
  isOpen: false,
  setOpen: (isOpen: boolean) => set({ isOpen }),
  setOpenStore: () => set({ isOpen: true }),
}));

export default useFeaturebaseStore;
