/* eslint-disable prefer-rest-params */
/* eslint-disable immutable/no-mutation */
'use client';
import Script from 'next/script';
import useFeaturebaseStore from '@/client/hooks/useFeaturebaseStore';

type Props = {
  email?: string;
  locale: string;
  organization?: string;
  theme: string;
  id?: string;
  name?: string;
};

const FeaturebaseScript: FC<Props> = ({
  email,
  locale,
  organization = 'bitcasino',
  theme,
  id,
  name,
}) => {
  const { isOpen } = useFeaturebaseStore();

  if (!isOpen) {
    return null;
  }

  return (
    <Script
      src="https://do.featurebase.app/js/sdk.js"
      id="featurebase-sdk"
      strategy="lazyOnload"
      onLoad={() => {
        if (typeof window.Featurebase !== 'function') {
          window.Featurebase = function () {
            (window.Featurebase.q = window.Featurebase.q || []).push(arguments);
          };
        }
        window.Featurebase('initialize_feedback_widget', {
          organization,
          theme,
          email,
          locale,
        });
        Featurebase('identify', { email, name, id, organization });
      }}
    />
  );
};

export default FeaturebaseScript;
