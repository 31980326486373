import(/* webpackMode: "eager" */ "/home/runner/work/next-casino/next-casino/app/global.css");
import(/* webpackMode: "eager" */ "/home/runner/work/next-casino/next-casino/app/src/client/components/CashierWrap/index.tsx");
import(/* webpackMode: "eager" */ "/home/runner/work/next-casino/next-casino/app/src/client/components/MarketingTagsClient/index.tsx");
import(/* webpackMode: "eager" */ "/home/runner/work/next-casino/next-casino/app/src/server/components/AnalyticsWorker/AnalyticsWorkerHandler/helpers/SetUserGtmInfo.ts");
import(/* webpackMode: "eager" */ "/home/runner/work/next-casino/next-casino/app/src/server/components/AnalyticsWorker/AnalyticsWorkerHandler/index.tsx");
import(/* webpackMode: "eager" */ "/home/runner/work/next-casino/next-casino/app/src/server/components/ExternalScripts/CashierAgent/CashierAgentScript/index.tsx");
import(/* webpackMode: "eager" */ "/home/runner/work/next-casino/next-casino/app/src/server/components/ExternalScripts/Featurebase/FeaturebaseScript/index.tsx");
import(/* webpackMode: "eager" */ "/home/runner/work/next-casino/next-casino/app/src/server/components/ExternalScripts/GroupIbSnippet/SnippetInitializer/index.tsx");
import(/* webpackMode: "eager" */ "/home/runner/work/next-casino/next-casino/app/src/server/components/ExternalScripts/LiveChatScript/CommsScript/index.tsx");
import(/* webpackMode: "eager" */ "/home/runner/work/next-casino/next-casino/app/src/server/components/NotificationWorker/NotificationWorkerHandler/index.tsx");
import(/* webpackMode: "eager" */ "/home/runner/work/next-casino/next-casino/app/src/server/components/StoreAbTestData/ExecuteStoreAbTestData/index.tsx");
import(/* webpackMode: "eager" */ "/home/runner/work/next-casino/next-casino/app/src/server/components/TwoFAManaging/TwoFADialog.tsx");
import(/* webpackMode: "eager" */ "/home/runner/work/next-casino/next-casino/node_modules/next/dist/client/script.js");
import(/* webpackMode: "eager" */ "/home/runner/work/next-casino/next-casino/node_modules/nextjs-toploader/dist/index.js")