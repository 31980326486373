import { CASINO_IMGIX, CDN_URL } from '@/shared/cdn/url';

const bitcasino: SiteConfig = {
  name: 'Bitcasino',
  defaultLang: 'en',
  languages: [
    'en',
    'ja',
    'pt',
    'zh',
    'es',
    'ko',
    'th',
    'de',
    'fr',
    'vi',
    'tr',
    'ar',
  ],
  domain: 'bitcasino.io',
  refCode: 'bcseo',
  isBridgeSite: false,
  fontTypes: ['Space Grotesk', 'DM Sans'],
  iconSetCdnPath: `${CDN_URL}/luxury/icons`,
  iconSetExt: '.png',
  defaultColorScheme: 'light',
  blogCategories: [
    'slots',
    'live-dealer',
    'tipshackstricks',
    'promotions',
    'vip',
    'cryptocurrency',
    'loyalty',
    'esports',
  ],
  privacyPolicyLink:
    '/help-center/help-terms-and-conditions/bitcasino-privacy-policy',
  registerButtonText: 'profile.create_account',
  gtmId: 'GTM-KSZ5BHR',
  menuItemsConfig: {
    common: {
      first: 5,
      filterName: '^(?!(live|bombay).*$)',
    },
    live: {
      first: 5,
      filterName: '^live',
    },
  },
  commsConfig: {
    productId: 10,
    productToken: '56D260AB0232F01AC87B3AFF665348FA518DEEE9',
    theme: {
      buttonColor: '#000000',
      buttonColorActive: '#000000',
      chatBubbleColor: '#000000',
      chatButtonColorActive: '#FFFFFF',
      chatButtonColor: '#FFFFFF',
      notificationColor: '#FFFFFF',
      notificationTextColor: '#000000',
      chatButtonIconColor: '#000000',
    },
    styles: {
      button: {
        border: '1px solid #E8E8E8',
      },
    },
  },
  protoConfig: {
    gwId: 'gw_hWGneEJutXAqZOvHpaP7dx',
  },
  umamiScript: true,
  smartlookConfig: {
    isScriptEnabled: true,
    projectId: '09fdf4a31c84e2f56d7399cc97ad7850c7fbb8ec',
  },
  convertComConfig: {
    isScriptEnabled: true,
    url: 'https://cdn-4.convertexperiments.com/v1/js/10042537-10048780.js',
  },
  icons: {
    icon: [
      {
        url: `https://${CASINO_IMGIX}/bitcasino/favicon/bc-32x32.png`,
        sizes: '32x32',
        type: 'image/png',
      },
      {
        url: `https://${CASINO_IMGIX}/bitcasino/favicon/bc-16x16.png`,
        sizes: '16x16',
        type: 'image/png',
      },
    ],
    apple: [
      {
        sizes: '180x180',
        url: `https://${CASINO_IMGIX}/bitcasino/favicon/bc-180x180.png`,
      },
    ],
    other: [
      {
        rel: 'mask-icon',
        url: `https://${CASINO_IMGIX}/bitcasino/favicon/safari-pinned-tab.svg`,
        color: '#F2590D',
      },
    ],
  },
  themeColor: '#F2590D',
  seo: {
    title: 'general.title',
    description: 'general.meta_description',
    og: {
      title: 'og.title',
      description: 'og.description',
      image: `https://${CASINO_IMGIX}/bitcasino/images/logo-bitcasino-orange.jpg`,
    },
  },
  bcioSiteId: '3',
  featurebaseOrganization: 'bitcasino',
};

export default bitcasino;
